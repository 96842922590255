<template>
    <router-link :to="getBrand(id)">
        <div class="solution">

            <div class="main-img">
                <img :src="mainImg" alt="" />
            </div>
            <div class="text">
                <h2 class="title">
                    {{ title }}
                </h2>
                <p class="description">{{ description }}</p>
                <div class="logos">
                    <div class="logo" v-for="logo in logos">
                        <img :src="logo" alt="">
                    </div>
                </div>
            </div>

        </div>
    </router-link>
</template>
    
<script >
export default {
    props: ['id', 'mainImg', 'title', 'description', 'logos'],
    methods: {
        getBrand(solutionId) {
            return `/products/${solutionId}`
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

a {
    width: 100%;

    .solution {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

        &:hover {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            gap: 5px;
        }

        .main-img {
            width: 25%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            overflow: hidden;
            background-color: $main_BG_color1;
            box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 767px) {
                width: 50%;
            }

            // img {
            //     width: 100%;
            //     aspect-ratio: 1/1;
            //     object-fit: contain;
            // }
        }

        .text {
            width: 75%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: 767px) {
                width: 90%;
            }

            .title {
                width: 100%;
                font-weight: bold;
                font-size: 25px;
                text-transform: uppercase;
                margin-bottom: 10px;
                color: black;
                background-color: $main_BG_color;


                a {
                    color: black;

                }

                @media (max-width: 767px) {
                    font-size: 20px;

                }
            }

            .description {
                font-size: 15px;
                line-height: 25px;
                color: #888;
                text-align: justify;
                margin-bottom: 20px;
                padding: 10px;

                @media (max-width: 767px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .logos {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    width: 100px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    overflow: hidden;
                    background-color: $alt_1_BG_color;
                    // border: 1px solid;
                    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
                    // box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media (max-width: 767px) {
                        width: 60px;
                    }

                    img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }
                }
            }
        }

    }
}
</style>