import HOST from "../const";

export default {
  async sendContactForm(context, payload) {
    console.log("try to send form");

    const response = await fetch(`${HOST}/api/contact/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        first_name: payload.firstName,
        last_name: payload.lastName,
        email: payload.email,
        company_name: payload.companyName,
        message: payload.description,
      }),
    });

    const responseData = await response.json();
    console.log("responseData",responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to send request!"
      );
      throw error;
    }

    console.log("form was sent sccesfuly!!!!");

  },
};
