<template>
    <base-dialog :show="isLoading" title="... Load Product ...">
        <base-spinner></base-spinner>
    </base-dialog>
    <div class="header-img">
        <the-header />
    </div>
    <base-container>
        <div class="product-details">
            <div class="title"> {{ name }} </div>
            <section class="details">

                <div class="description-feature">
                    <div v-html="description"> </div>
                    <div class="features">
                        <h3>Main Features:</h3>
                        <li class="feature" v-for="feature in features" :key="feature.id"> N {{ feature.name }}</li>
                    </div>
                </div>
                <div class="image">
                    <ProductImgSlider :images="img" />
                </div>

            </section>
            <div class="datasheet" v-if="dataSheet != ''">
                <a :href="dataSheet" target="_blank">
                    <font-awesome-icon :icon="['fas', 'file-arrow-down']" />
                    <span>Datasheet</span>
                </a>
            </div>
            <div class="datasheet" v-for="attachment in product_attachments" :key="attachment.id">
                <a :href="attachment.file" target="_blank">
                    <font-awesome-icon :icon="['fas', 'file-arrow-down']" />
                    <span>{{ attachment.type }}</span>
                </a>
            </div>
        </div>
    </base-container>
</template>
    
<script >
import ProductImgSlider from '../brands/ProductImgSlider.vue';
export default {
    components: {},
    props: ['productId'],
    components: { ProductImgSlider },
    data() {
        return {
            name: '',
            shortDescription: '',
            description: '',
            features: [],
            img: [],
            dataSheet: '',
            product_attachments: [],
            // showDialog: false,
            // ErrorMsg: '',
            isLoading: false,
        }
    },
    created() {
        this.loadProductDetails();
    },
    methods: {
        async loadProductDetails() {
            this.isLoading = true;

            console.log('product details 1 ...')
            try {
                await this.$store.dispatch('brands/loadProductDetails', { productId: this.productId });
                this.getProductDetails()
            } catch (error) {
                this.error = error.message || 'Something went wrong!';
            }
            this.isLoading = false;

        },
        getProductDetails() {
            const product = this.$store.getters["brands/product"];
            this.name = product.name;
            this.shortDescription = product.short_description;
            this.description = product.description;
            this.features = product.features;
            this.img = product.product_photos;
            this.dataSheet = product.data_sheet;
            this.product_attachments = product.product_attachments;
            // console.log('shortDescription: ' + this.shortDescription)
        },
    },

};
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.header-img {
    background-image: url('../../assets/images/Untitled-2-01.jpg');
    background-size: cover;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15% 50%/0 0 30% 60%;

    @media (max-width: 767px) {
        border-radius: 0;
        overflow: visible;
        height: 150px;
    }

    position: relative;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(82, 75, 46, 0.507) 0%, rgba(240, 241, 241, 0.192) 100%);

    }
}

.product-details {
    margin: 100px auto;

    .title {
        text-transform: capitalize;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
        margin-bottom: 30px;
    }

    .details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 50px;

        @media (max-width: 767px) {
            flex-direction: column-reverse;
            align-items: center;
        }

        .description-feature {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;

            @media (max-width: 767px) {
                width: 100%;

            }

            .description {
                text-align: start;
                color: $alt_4_color;
                line-height: 23px;
            }

            .features {
                list-style: none;
                text-align: start;

                h3 {
                    margin-bottom: 10px;
                    text-transform: capitalize;
                    text-align: start;
                }

                .feature {
                    text-align: start;
                    padding-left: 10px;
                    color: $alt_4_color;
                    line-height: 23px;

                }
            }
        }

        .image {
            width: 30%;
            height: 300px;
            // border: 1px solid;

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .datasheet {
        display: inline-block;
        margin-right: 7px;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 50px;
                height: 50px;
                color: $alt_color;
            }

            span {
                font-size: 15px;
                line-height: 50px;
                text-transform: capitalize;
                color: $main_color;
            }

        }
    }
}
</style>